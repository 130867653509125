<template>
  <el-form
    :model="props.model"
    :label-position="props.labelPosition"
    :class="props.classCustom"
    :rules="props.rules"
  >
    <slot />
  </el-form>
</template>

<script setup>
const props = defineProps({
  model: {
    type: Object,
    default: null
  },
  rules: {
    type: Object,
    default: null
  },
  classCustom: {
    type: String,
    default: ''
  },
  labelPosition: {
    type: String,
    default: 'right'
  }
})
</script>

<style scoped></style>
